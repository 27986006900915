(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("emClientRequestOfferDialogController", emClientRequestOfferDialogController);

	function emClientRequestOfferDialogController($scope, $http, $mdDialog, errorHandling, $filter, userDataService) {
		const vm = this;

		vm.countyCode = "US";
		vm.organizationName = "";
		vm.fullName = "";
		vm.countrySearch = "";
		vm.ownerEmail = "";
		activate();

		///////////////////////
		vm.clearSearchTerm = function() {
			vm.countrySearch = "";
		};
		vm.filterCountrySearch = function(item) {
			return item.translation.toLowerCase().startsWith(vm.countrySearch.toLowerCase()) || item.key.toLowerCase().startsWith(vm.countrySearch.toLowerCase());
		}
		function activate() {
			$http.get("~/api/v1/settings/domain/country-codes")
				.then(onCountryCodesLoaded, errorHandling.report);
		}
		function onCountryCodesLoaded(result) {
			vm.countries = [];
			angular.forEach(result.data.results, function (key) {
				vm.countries.push({ key: key, translation: $filter("translate")("COUNTRY_CODE_" + key) });
			});
		}
		vm.countryListChanged = function(form) {

		}
		vm.clearSearchTerm = function() {
			vm.countrySearch = "";
		}
		vm.request = function() {
			$mdDialog.hide({
				countryCode: vm.countryCode, 
				organizationName: vm.organizationName, 
				fullName: vm.fullName,
				ownerEmail: vm.ownerEmail
			});
		}

		vm.cancel = function() {
			$mdDialog.cancel();
		}

	}

})();