(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("editOwnFolderDialogController", editOwnFolderDialogController);

    function editOwnFolderDialogController($scope, $mdDialog, $http, $filter, $timeout, errorHandling, errorMessageService, folderId, coreDataSettings, successHandling, coreLicensing) {
        var vm = this;
        var FOLDERTYPE_NOTES = 5;
        var FOLDERTYPE_CONTACTS = 1;
        var FOLDERTYPE_APPOINTMENTS = 2;
        var FOLDERTYPE_TASKS = 4;
        vm.folderId = folderId;
        vm.ready = false;
        vm.edition = 2;
        vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
        vm.userPermissions = [];
        vm.groupPermissions = [];
        vm.userGroups = [];
        vm.selectedItem = [];
        vm.stopSharingVisible = false;

        // functions
        vm.cancel = cancel;
        vm.deleteThis = deleteThis;
        vm.save = save;
        vm.focusUserInput = focusUserInput;
        vm.stopSharing = stopSharing;
        vm.removeUser = removeUser;
        vm.removeGroup = removeGroup;
        vm.queryUsers = queryUsers;
        vm.onEmailChanged = onEmailChanged;
        vm.onEmailBlurred = onEmailBlurred;
        vm.onGroupChanged = onGroupChanged;
        vm.validateHexColor = validateHexColor;

        // Startup
        activate();

        ///////////////////////////////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            // TODO: Support for adding a new calendar / contact list / whatever
            if (!vm.folderId)
                cancel();

            $http.get("~/api/v1/folders/get-owned-virtual-folder/" + vm.folderId)
                .then(function (success) {
                    vm.ready = true;
                    if (!success.data.success) {
                        cancel();
                        return;
                    }
                    vm.folder = success.data.virtualFolder;
                    onFolderLoaded();
                }, errorHandling.report);
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
        }

        function onFolderLoaded() {
            vm.originalValues = {
                isPrimary: vm.folder.isPrimary
            };

            vm.stopSharingVisible = (vm.folder.accessUsers && vm.folder.accessUsers.length) || (vm.folder.accessGroups && vm.folder.accessGroups.length);
            vm.host = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

            switch (vm.folder.folderType) {
                case FOLDERTYPE_NOTES:
                    vm.typeName = "notes";
                    vm.primaryOptionDisplayName = translate("NOTES_DEFAULT_FOLDER");
                    break;
                case FOLDERTYPE_CONTACTS:
                    vm.typeName = "contacts";
                    vm.primaryOptionDisplayName = translate("ADDRESS_BOOK_DEFAULT");
                    break;
                case FOLDERTYPE_APPOINTMENTS:
                    vm.typeName = "appointments";
                    vm.primaryOptionDisplayName = translate("CALENDAR_DEFAULT");
                    break;
                case FOLDERTYPE_TASKS:
                    vm.typeName = "tasks";
                    vm.primaryOptionDisplayName = translate("TASKS_DEFAULT_FOLDER");
                    break;
                default:
                    vm.typeName = "email";
                    break;
            }

            coreDataSettings.settingsData.userGroups
                .then(function (success) {
                    var i;

                    vm.userGroups.push({ groupName: $filter("translate")("SELECT_A_GROUP"), userGroupId: undefined, selectText: true });
                    vm.userGroups.push({ groupName: $filter("translate")("EVERYONE"), userGroupId: -1, isEveryone: true });
                    vm.userGroups.push({ groupName: $filter("translate")("SHARED_RESOURCES_ADMIN"), userGroupId: -2, isAdmins: true });
                    var grps = $.extend(true, [], success);
                    for (i = 0; i < grps.length; i++) {
                        vm.userGroups.push({ groupName: grps[i].name, userGroupId: grps[i].id });
                    }

                    for (i = 0; i < vm.folder.accessUsers.length; ++i) {
                        vm.userPermissions.push(vm.folder.accessUsers[i]);
                        vm.selectedItem[i] = vm.folder.accessUsers.username;
                    }
                    vm.userPermissions.push({ access: 4, user: { username: "" } });

                    angular.forEach(vm.folder.accessGroups, function (share) {
                        vm.groupPermissions.push(share);
                    });

                    if (vm.groupPermissions.length > 0) {
                        angular.forEach(vm.groupPermissions, function (share) {
                            for (i = 0; i < vm.userGroups.length; i++) {
                                if (share.userGroupId == vm.userGroups[i].userGroupId) {
                                    share.group = vm.userGroups[i];
                                    break;
                                }
                            }
                        });
                    }
                    vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
                });
        }

        function validateHexColor() {
            if (vm.typeName != 'appointments' && vm.typeName != 'tasks')
                return true;
            return coreDataSettings.validateHexSixColor(ctrl.folder.color);
        }

        function queryUsers(query) {
            return coreDataSettings.queryUsers(query);
        }

        function onEmailChanged(last, index, form) {
            var email = form["email" + index].$viewValue;
            if (email && email.length > 0 && last) {
                vm.userPermissions.push({ access: 4, user: { username: "" } });
            }
        };

        function onEmailBlurred(last, index, form) {
            var email = form["email" + index].$viewValue;
            if ((!email || email.length === 0) && !last) {
                vm.selectedItem.splice(index, 1);
                vm.userPermissions.splice(index, 1);
            }
        };

        function onGroupChanged(last) {
            if (last) {
                vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
            }
        };

        function removeUser(index, form) {
            vm.selectedItem.splice(index, 1);
            vm.userPermissions.splice(index, 1);
            form.$setDirty();
        }

        function removeGroup(index, form) {
            vm.groupPermissions.splice(index, 1);
            form.$setDirty();
        }

        function focusUserInput() {
            $timeout(function () {
                $("md-autocomplete input").last().trigger("focus");
            });
        }

        //function addCalendar() {
        //    var params = {
        //        setting: {
        //            friendlyName: vm.name,
        //            calendarViewColor: vm.color,
        //            isPrimary: vm.isPrimary
        //        }
        //    };

        //    $http
        //        .post("~/api/v1/calendars/calendar-put", JSON.stringify(params))
        //        .then(onCalendarPutSuccess, errorHandling.report);

        //    function onCalendarPutSuccess(success) {
        //        var newSource = {
        //            color: vm.color,
        //            id: success.data.result,
        //            isCalendar: true,
        //            isConferenceRoom: false,
        //            isPrimary: vm.isPrimary,
        //            isSharedItem: false,
        //            isTask: false,
        //            isVisible: true,
        //            name: vm.name,
        //            owner: coreData.user.username,
        //            permission: 8
        //        };

        //        coreDataCalendar
        //            .addCalendarSource(newSource)
        //            .then(function () {
        //                source = newSource;
        //                vm.sourceName = newSource.id;
        //                savePermissions(true);
        //            }, errorHandling.report);
        //    }
        //}

        //function saveCalendar() {
        //    var params = {
        //        setting: {
        //            friendlyName: vm.name != vm.originalName ? vm.name : undefined,
        //            calendarViewColor: vm.color,
        //            isPrimary: vm.isPrimary,
        //            id: source.id
        //        }
        //    };

        //    $http
        //        .post("~/api/v1/calendars/calendar", JSON.stringify(params))
        //        .then(onSaveSuccess, errorHandling.report);

        //    function onSaveSuccess() {
        //        if (vm.isShareable)
        //            savePermissions();
        //        else
        //            $mdDialog.hide({ modified: true });
        //    }
        //}

     function save(event, form) {
         if (form.$invalid)
             return;

         // Save folder properties like name, color, default, etc
         if (!validateHexColor())
             return;

         // Save permissions
         // Clean everyone and admins
         if (!vm.folder.folderId) {
             vm.folder.folderType = vm.folder.type.value;
         }
         vm.folder.admin_access = 0;
         vm.folder.everyone_access = 0;
         vm.folder.users = [];
         vm.folder.usergroups = [];
         for (var i = 0; i < vm.groupPermissions.length; i++) {
             var g = vm.groupPermissions[i];
             if (g.group.userGroupId)
                 vm.folder.usergroups.push({ groupName: g.group.name, userGroupId: g.group.userGroupId, access: g.access });
         }
         for (var i = 0; i < vm.userPermissions.length; i++) {
             var g = vm.userPermissions[i];
             if (!g.username)
                 continue;
             vm.folder.users.push({ username: g.username, access: g.access });
         }

         var params = JSON.stringify({
             folderId: vm.folderId,
             accessUsers: vm.folder.users,
             accessGroups: vm.folder.usergroups
         });
         $http.post("~/api/v1/folders/set-owned-virtual-folder-properties", params)
             .then(function () {
                 $mdDialog.hide({ resource: vm.folder });
             });
        }

        function stopSharing(event) {
            $rootScope.spinner.show();
            $http.post("~/api/v1/folders/stop-sharing-folders", JSON.stringify({ folderIds: [vm.folderId] }))
                .then(function (success) {
                    $mdDialog.hide({ action: "refresh" });
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }

       function cancel() {
            $mdDialog.cancel();
        }

        function deleteThis(event) {
            if (vm.typeName != 'appointments') {
                if (vm.originalValues.isPrimary) {
                    errorHandling.report("CANNOT_DELETE_PRIMARY_FOLDER");
                    return;
                }
                $http
                    .post("~/api/v1/calendars/calendar-delete/" + source.id)
                    .then(onCalendarDeleteSuccess, errorHandling.report);

            }
            $mdDialog.hide({ action: "delete" });
        }

        //function deleteCalendar() {
        //    if (!vm.isPrimary && !vm.isConference) {
        //        $http
        //            .post("~/api/v1/calendars/calendar-delete/" + source.id)
        //            .then(onCalendarDeleteSuccess, errorHandling.report);

        //        function onCalendarDeleteSuccess() {
        //            coreDataCalendar
        //                .removeCalendarSource(source.id)
        //                .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
        //        }
        //    }
        //}

        function translate(key) {
            return $filter("translate")(key);
        }
    }

})();