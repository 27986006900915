(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataListener", coreDataListener);

    function coreDataListener($rootScope, userDataService, coreDataMail, coreDataCalendar, coreDataNotes, coreDataRss,
        coreDataContacts, coreDataTasks, coreDataFileStorage, coreDataCategories, coreDataSettings, AuthenticationService,
        signalrHubManager, apiCategories) {

        var vm = this;
        vm.started = false;

        // Exported functions
        vm.start = start;
        vm.onDisconnected = () => vm.started = false;

        // Initialization
        $rootScope.$on('signalR.mailHub.client.categoriesModified', onCategoriesModified);
        $rootScope.$on('signalR.mailHub.client.contactsDeleted', onContactsDeleted);
        $rootScope.$on('signalR.mailHub.client.contactsModified', onContactsModified);
        $rootScope.$on('signalR.mailHub.client.disposableAddressModified', onDisposableAddressModified);
        $rootScope.$on('signalR.mailHub.client.eventDeleted', onEventDeleted);
        $rootScope.$on('signalR.mailHub.client.eventModified', onEventModified);
        $rootScope.$on('signalR.mailHub.client.filesAdded', onFilesAdded);
        $rootScope.$on('signalR.mailHub.client.filesDeleted', onFilesDeleted);
        $rootScope.$on('signalR.mailHub.client.filesModified', onFilesModified);
        $rootScope.$on('signalR.mailHub.client.fileStorageFolderChange', onFileStorageFolderChange);
        $rootScope.$on('signalR.mailHub.client.folderChange', onFolderChange);
        $rootScope.$on('signalR.mailHub.client.galUpdate', onGalModified);
        $rootScope.$on('signalR.mailHub.client.logout', onLogout);
        $rootScope.$on('signalR.mailHub.client.mailAdded', onMailAdded);
        $rootScope.$on('signalR.mailHub.client.mailMigrationUpdate', onMailMigrationUpdate);
        $rootScope.$on('signalR.mailHub.client.mailModified', onMailModified);
        $rootScope.$on('signalR.mailHub.client.mailRemoved', onMailRemoved);
        $rootScope.$on('signalR.mailHub.client.meetingDeleted', onMeetingDeleted);
        $rootScope.$on('signalR.mailHub.client.messageFlagChange', onMessageFlagChange);
        $rootScope.$on('signalR.mailHub.client.notesDeleted', onNotesDeleted);
        $rootScope.$on('signalR.mailHub.client.notesModified', onNotesModified);
        $rootScope.$on('signalR.mailHub.client.rssContentsUpdated', onRssContentsUpdated);
        $rootScope.$on('signalR.mailHub.client.settingsModified', onSettingsModified);
        $rootScope.$on('signalR.mailHub.client.settingsModified_byAdmin', onAdminModifiedSettings);
        $rootScope.$on('signalR.mailHub.client.sharesChanged', onSharesChanged);
        $rootScope.$on('signalR.mailHub.client.tasksDeleted', onTasksDeleted);
        $rootScope.$on('signalR.mailHub.client.tasksModified', onTasksModified);
        //$rootScope.$on('signalR.mailHub.client.SpoolMessageAdded', onSpoolMessageAdded);
        //$rootScope.$on('signalR.mailHub.client.SpoolMessageStatusUpdate', onSpoolMessageStatusUpdate);

        // Implementation ---------------------------------------

        async function start(reconnect) {
            if (vm.started)
                return;
            vm.started = true;

            // Connect to SignalR and listen for requests. 
            // Call functions in core - data when something needs to be updated.
            signalrHubManager.coreDataListener = vm;

            try {
                // It's ok to init() multiple times
                await signalrHubManager.init();

                if (reconnect) {
                    coreDataMail
                        .loadMailTree(null, true)
                        .then(() => $rootScope.$broadcast('mail.reloadSources'), () => { });
                }

                AuthenticationService.setSignalRCallback(stop);
            } catch (err) {
                console.warn("SignalR Failure", err);
                vm.started = false;
            }
        }

        function stop() {
            if (!vm.started) return;
            signalrHubManager.userLogout();
            vm.started = false;
        }

        function onMailAdded(event, data) {
            coreDataMail.addMailItem(data);
            $rootScope.$broadcast("mailListAdded", data);
        }

        function onMailRemoved(event, data) {
            coreDataMail.removedMailItem(data);
            $rootScope.$broadcast("mailListRemoved", data);
        }

        function onMailModified(event, data) {
            coreDataMail.modifiedMailItem(data);
            $rootScope.$broadcast("mailListModified", data);
        }

        function onFolderChange(event) {
            if (Date.now() - coreDataMail.ignoreFolderUpdate.requested < 10000 &&
                coreDataMail.ignoreFolderUpdate.requested > coreDataMail.ignoreFolderUpdate.ignored) {
                coreDataMail.ignoreFolderUpdate.ignored = Date.now();
            } else {
                coreDataMail.loadMailTree(null, true)
                    .then(
                        () => $rootScope.$broadcast('mail.reloadSources'),
                        () => { }
                    );
            }
        }

        function onMessageFlagChange(event, data) {
        }

        //For contacts/notes/calendars/tasks, when a shared item is added and mapped the signalr updates get called, but the respective pages don't have a
        // reference to the source for the new shared item. In this case the methods would have to go back to the api to load the sources again and check if the
        // new shared item is there. This may not be a problem often enough though to warrant the extra api calls, just a note for the future.
        //There is another comment regarding this in MailSignalR.cs.

        function onGalModified(event, galEntry) {
            galEntry.forEach(function (updateEntry) {
                if (updateEntry.email.toLowerCase() == userDataService.user.emailAddress.toLowerCase())
                    userDataService.user.refreshPictureUrl();
            });
            coreDataContacts.modifyGalContact("gal");
        }

        function onContactsModified(event, modified) {
            angular.forEach(modified, function (value, key) {
                var deltaTime = moment().diff(coreDataContacts.ignoreContactModified.requested);
                if (deltaTime < 2000 ||
                    (deltaTime < 1000 && coreDataContacts.ignoreContactModified.requested > coreDataContacts.ignoreContactModified.ignored)) {
                    coreDataContacts.ignoreContactModified.ignored = moment();
                } else {
                    coreDataContacts.modifyContact(value.source, value.id);
                }
            });
        }

        function onContactsDeleted(event, data) {
            var deltaTime = moment().diff(coreDataContacts.ignoreContactRemoved.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataContacts.ignoreContactRemoved.requested > coreDataContacts.ignoreContactRemoved.ignored)) {
                coreDataContacts.ignoreContactRemoved.ignored = moment();
            } else {
                coreDataContacts.deleteContacts(data.owner, data.contactIds);
            }
        }

        function onNotesModified(event, modified) {
            angular.forEach(modified, function (value, key) {
                var deltaTime = moment().diff(coreDataNotes.ignoreNoteModified.requested);
                if (deltaTime < 2000 ||
                    (deltaTime < 1000 && coreDataNotes.ignoreNoteModified.requested > coreDataNotes.ignoreNoteModified.ignored)) {
                    coreDataNotes.ignoreNoteModified.ignored = moment();
                } else {
                    coreDataNotes.modifyNote(value.source, value.sourceId, value.id);
                }
            });
        }

        function onNotesDeleted(event, data) {
            var deltaTime = moment().diff(coreDataNotes.ignoreNoteRemoved.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataNotes.ignoreNoteRemoved.requested > coreDataNotes.ignoreNoteRemoved.ignored)) {
                coreDataNotes.ignoreNoteRemoved.ignored = moment();
            } else {
                coreDataNotes.deleteNotes(data.owner, data.uids);
            }
        }

        function onRssContentsUpdated(event, data) {
            coreDataRss.fireRssContentsUpdated(data);
        }

        function onEventModified(event, modified) {
            //If the event is under the delta time that means that the client probably changed the item and we don't need to have signalr update it again.
            //If the ignored time is greater than the requested time and still within a reasonable threshold this means we probably ignored the request once already
            // but we probably don't want to ignore it again.
            var deltaTime = moment().diff(coreDataCalendar.ignoreCalendarEventModified.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataCalendar.ignoreCalendarEventModified.requested > coreDataCalendar.ignoreCalendarEventModified.ignored)) {
                coreDataCalendar.ignoreCalendarEventModified.ignored = moment();
            } else {
                angular.forEach(modified, function (value) {
                    coreDataCalendar.loadCalendarEvents(value.source, value.id);
                });
            }
        }

        function onEventDeleted(event, events) {
            var deltaTime = moment().diff(coreDataCalendar.ignoreCalendarEventRemoved.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataCalendar.ignoreCalendarEventRemoved.requested > coreDataCalendar.ignoreCalendarEventRemoved.ignored)) {
                coreDataCalendar.ignoreCalendarEventRemoved.ignored = moment();
            } else {
                coreDataCalendar.removeCalendarEvents(events);
            }
        }

        function onTasksModified(event, data) {
            var deltaTime = moment().diff(coreDataTasks.ignoreTaskModified.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataTasks.ignoreTaskModified.requested > coreDataTasks.ignoreTaskModified.ignored)) {
                coreDataTasks.ignoreTaskModified.ignored = moment();
            } else {
                coreDataCalendar.loadCalendarTasks(data.source, data.id);
                coreDataTasks.modifyTasksSignal(data.source, data.id);
            }

            $rootScope.$broadcast('mail.refreshEmailTask');
        }

        function onTasksDeleted(event, data) {
            var deltaTime = moment().diff(coreDataTasks.ignoreTaskRemoved.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataTasks.ignoreTaskRemoved.requested > coreDataTasks.ignoreTaskRemoved.ignored)) {
                coreDataTasks.ignoreTaskRemoved.ignored = moment();
            } else {
                coreDataCalendar.removeCalendarTasks(data.owner, data.taskIds);
                coreDataTasks.removeTasksSignal(data.owner, data.taskIds);
            }
            $rootScope.$broadcast('mail.refreshEmailTask');
        }

        function onFileStorageFolderChange(event, data) {
            //var deltaTime = moment().diff(coreDataFileStorage.ignoreFolderChanges.requested);
            //if (deltaTime < 2000 ||
            //    (deltaTime < 1000 && coreDataFileStorage.ignoreFolderChanges.requested > coreDataFileStorage.ignoreFolderChanges.ignored)) {
            //    coreDataFileStorage.ignoreFolderChanges.ignored = moment();
            //} else {
            //    if (data) {
            //        coreDataFileStorage.folderChange(data);
            //    }
            //}
            if (data) {
                coreDataFileStorage.folderChange(data);
            }
        }

        function onFilesAdded(event, data) {
            var deltaTime = moment().diff(coreDataFileStorage.ignoreFileAdds.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataFileStorage.ignoreFileAdds.requested > coreDataFileStorage.ignoreFileAdds.ignored)) {
                coreDataFileStorage.ignoreFileAdds.ignored = moment();
            } else {
                coreDataFileStorage.addFiles(data);
            }
        }

        function onFilesDeleted(event, data) {
            var deltaTime = moment().diff(coreDataFileStorage.ignoreFileDeletes.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataFileStorage.ignoreFileDeletes.requested > coreDataFileStorage.ignoreFileDeletes.ignored)) {
                coreDataFileStorage.ignoreFileDeletes.ignored = moment();
            } else {
                coreDataFileStorage.removeFiles(data);
            }
        }

        function onFilesModified(event, data) {
            var deltaTime = moment().diff(coreDataFileStorage.ignoreFileChanges.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataFileStorage.ignoreFileChanges.requested > coreDataFileStorage.ignoreFileChanges.ignored)) {
                coreDataFileStorage.ignoreFileChanges.ignored = moment();
            } else {
                coreDataFileStorage.modifyFiles(data);
            }
        }

        function onAdminModifiedSettings(event, data) {
            $rootScope.$broadcast("user-settings:changed");
            if (!data.language)
                return;
            location.reload();
        }

        function onSettingsModified(event, data) {
            coreDataCalendar.resetSources();
        }

        function onMailMigrationUpdate(event, data) {
            coreDataSettings.settingsData.migrationStatus = data;
        }

        //This wouldn't work as they are anyways, if we ever send these to the frontend again change them.
        //function onSpoolMessageAdded(event, data) {
        //	$rootScope.$broadcast('spoolMessageAdded', { data: data.data.data });
        //};
        //
        //function onSpoolMessageStatusUpdate(event, data) {
        //	data.data.data.fileName = data.fileName;
        //	$rootScope.$broadcast('spoolMessageStatus', { data: data.data.data });
        //};

        function onSharesChanged(event, data) {
            switch (data.shareType) {
                case "calendar":
                    coreDataCalendar.reloadOnEnterCalendar = true;
                    break;
                case "mail":
                    coreDataMail.reloadMailTreeOnEnter = true;
                    break;
                case "contacts":
                    coreDataContacts.reloadOnEnter = true;
                    break;
                case "tasks":
                    coreDataTasks.reloadOnEnter = true;
                    break;
                case "notes":
                    coreDataNotes.reloadOnEnter = true;
                    break;
                default:
                    if (!data.shareType) {
                        coreDataCalendar.reloadOnEnterCalendar = true;
                        coreDataMail.reloadMailTreeOnEnter = true;
                        coreDataContacts.reloadOnEnter = true;
                        coreDataNotes.reloadOnEnter = true;
                        coreDataTasks.reloadOnEnter = true;
                    }
                    break;
            }
            onCategoriesModified();
        }

        function onLogout() {
            AuthenticationService.Logout("core data service (onLogout)");
        }

        async function onCategoriesModified() {
            coreDataCategories.reset();
            apiCategories.invalidateCategories();
            apiCategories.init();
            $rootScope.$broadcast('categoriesUpdated');
        }

        function onDisposableAddressModified() {
            $rootScope.$broadcast('disposableAddressModified');
        }

        function onMeetingDeleted(event, data) {
            var deltaTime = moment().diff(coreDataFileStorage.ignoreFolderChanges.requested);
            if (deltaTime < 2000 ||
                (deltaTime < 1000 && coreDataFileStorage.ignoreFolderChanges.requested > coreDataFileStorage.ignoreFolderChanges.ignored)) {
                coreDataFileStorage.ignoreFolderChanges.ignored = moment();
            } else {
                if (data) {
                    coreDataFileStorage.removeMeetingFolders([data]);
                }
            }
        }
    }

})();