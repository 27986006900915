(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("moveEmailController", moveEmailController);

	function moveEmailController($scope, $mdDialog, $http, $timeout, coreDataMail, folders, folder, owner) {
		$scope.controller = this;
		$scope.selectedNode = "";
		$scope.title = 'MOVE_EMAIL';
		$scope.owner = owner;
		$scope.folders = $.grep(folders, function (f) { return f.name != folder && f.access !== 2; });
		$scope.selectedFolder = $scope.folders[0];

		$scope.done = function () {
			$mdDialog.hide({ 'success': true, 'folder': $scope.selectedFolder.path, 'owner': $scope.selectedFolder.ownerEmailAddress });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();