(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userAdditionalInfoController", userAdditionalInfoController);

	function userAdditionalInfoController($scope, $mdDialog, infoText) {
		$scope.infoText = infoText;
		
		$scope.save = function () {
			$mdDialog.hide($scope.infoText);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
