(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("revertSysAdminChangesModalController", revertSysAdminChangesModalController);

	function revertSysAdminChangesModalController($scope, $mdDialog) {
		$scope.done = function () {
			$mdDialog.hide();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();