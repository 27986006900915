(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("messageData", messageDataService);

    function messageDataService($filter, userDataService, meetingInfoData, apiCategories) {
        var vm = this;
        var _access = 0;
        vm.message = {

        };
        vm.messageCategories = [];
        vm.markAsReadQueue = [];
        vm.messageLoaded = false;
        vm.navigationPacket = {};
        vm.reloadCategories = function () {
            if (vm.messageLoaded) {
                apiCategories.categoryOwner = vm.message.ownerEmailAddress;
                vm.messageCategories = apiCategories.getCategories();
            } else {
                vm.messageCategories = [];
            }
            setupMessageCategories();
        };
        // Convenience properties
        vm.prop = {
            get AmIMessageOwner() {
                return vm.message && (!vm.message.ownerEmailAddress || vm.message.ownerEmailAddress === "" || vm.message.ownerEmailAddress.toLowerCase() === userDataService.user.emailAddress.toLowerCase());
            },
            get fullAccess() {
                return this.AmIMessageOwner || this.access !== 2;
            },
            get access() {
                return _access;
            },
            set access(value) {
                _access = value;
            }
        };

        var selectionDefaults = {
            hasSeen: false,
            hasUnseen: false,
            isDraft: false,
            hasFlagged: false,
            hasUnflagged: false,
            isDeleted: false
        };
        vm.selection = selectionDefaults;
        vm.getMessage = function () {
            return vm.message;
        };
        vm.loadMessage = function (msg) {
            vm.message = msg;
            vm.messageLoaded = vm.message.uid !== undefined;
            if (vm.messageLoaded) vm.refreshSelection([vm.message]);
            if (vm.message.meetingInfo) {
                meetingInfoData.loadMeetingInfo(vm.message.meetingInfo);
                vm.message.meetingInfo.prop = meetingInfoData.prop;
                // adjust buttons for sent, junk or deleted folder
                vm.message.meetingInfo.showAccept = vm.message.meetingInfo.showAccept && !vm.message.isDeleted && !vm.message.meetingInfo.isOrganizer;
                vm.message.meetingInfo.showDecline = vm.message.meetingInfo.showAccept;
            }
            if (!vm.message.ownerEmailAddress || vm.message.ownerEmailAddress === "")
                vm.message.ownerEmailAddress = userDataService.user.emailAddress.toLowerCase();
            vm.reloadCategories();
        };

        function setupMessageCategories() {
            // Reset all categories that were previously set
            vm.messageCategories.forEach(function (cat) { 
                cat.selected = false; 
            });

            // Loop through all categories on the item, which could include items not on our master category list
            if (vm.message && vm.message.categories) {
                vm.messageCategories = vm.message.categories.reduce(function (res, cat) {
                    const masterCat = res.find(c => c.name === cat);
                    if (masterCat) {
                        masterCat.selected = true;
                    } else {
                        var translatedName = $filter("translate")(cat);
                        res.push({ name: cat, colorIndex: -1, master: false, translatedName: translatedName, selected: true });
                    }
                    return res;
                }, 
                vm.messageCategories);
            }
        }

        vm.unloadMessage = function () {
            vm.messageLoaded = false;
            vm.message = {};
            vm.selection = selectionDefaults;
            meetingInfoData.loadMeetingInfo(null);
        };
        vm.refreshSelection = function (selectedItems) {
            vm.selection.isDraft = selectedItems.every(item => item.isDraft || false);
            vm.selection.hasSeen = selectedItems.some(item => item.isSeen || false);
            vm.selection.hasFlagged = selectedItems.some(item => item.isFlagged || false);
            vm.selection.hasUnseen = selectedItems.some(item => !item.isSeen);
            vm.selection.hasUnflagged = selectedItems.some(item => !item.isFlagged);
            vm.selection.isDeleted = selectedItems.every(item => item.isDeleted || false);
            if (vm.message) {
                meetingInfoData.loadMeetingInfo(vm.message.meetingInfo);
                if (vm.message.meetingInfo)
                    vm.message.meetingInfo.prop = meetingInfoData.prop;
            }
        };

        vm.ownerHasCategories = function () {
            if (vm.message === undefined)
                return false;

            var owner = vm.message.ownerEmailAddress;
            if (owner === undefined || owner.length < 1)
                return false;

            var sharedCats = vm.sharedMessageCategories[owner];
            if (sharedCats === undefined || sharedCats.categories === undefined)
                return false;

            return sharedCats.categories.length > 0;
        };

        vm.getOwnersCategories = function () {
            if (vm.message === undefined)
                return;

            var owner = vm.message.ownerEmailAddress;
            if (owner === undefined || owner.length < 1)
                return;

            var sharedCats = vm.sharedMessageCategories[owner];
            if (sharedCats === undefined || sharedCats.categories === undefined)
                return;

            return sharedCats.categories;
        }
    }

})();
