(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("calendarMoveDialogController", calendarMoveDialogController);

	function calendarMoveDialogController($scope, $mdDialog, calendars, coreData) {
		$scope.calendars = [];
		angular.forEach(calendars, function (value, key) {
			if (value.owner == coreData.user.username) {
				$scope.calendars.push(value)
			}
		});

		$scope.currentCalendar = {};
		if ($scope.calendars.length > 0) {
			$scope.currentCalendar = calendars[0];
		}

		$scope.done = function () {
			$mdDialog.hide($scope.currentCalendar);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	};
})();