(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userPasswordPolicySendController", userPasswordPolicySendController);

	function userPasswordPolicySendController($scope, $mdDialog, $timeout, from, to, subject, emailText) {
		$scope.emailInfo = {
			from: from,
			to: to,
			subject: subject,
			emailText: emailText
		};
		$scope.from = from;
		$scope.to = to;
		$scope.subject = subject;
		$scope.emailText = emailText;
		$scope.editorScope = {};

		$scope.save = function () {
			$scope.emailInfo.emailText = $scope.editorScope.getHtml();

			$timeout(function () {
				if ($scope.passwordForm.$valid) {
					$mdDialog.hide($scope.emailInfo);
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
