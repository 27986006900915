(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("dmarcSpamCtrl", dmarcSpamCtrl);

	function dmarcSpamCtrl($rootScope, $scope, $mdDialog, $http, errorHandling) {

		activate();

		/////////////////

		function activate() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(onLoad, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onLoad(result) {
				$scope.item = angular.copy(result.data.settings.dmarc);
			}
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/antispam/settings", JSON.stringify({ dmarc: $scope.item }))
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};
	}
})();