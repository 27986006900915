(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminNewEventController", sysAdminNewEventController);

	function sysAdminNewEventController($scope, $mdDialog, $filter, modEvent, modelWaits, availableEvents) {
		var vm = this;

		$scope.settingAdjustWait = modelWaits[0];
		$scope.settingBlurWait = modelWaits[1];
		$scope.isNameValid = true;
		$scope.isValid = true;
		$scope.modEvent = modEvent;

		vm.eventCategoryOptions = [
			// Structure Example
			// { value: 20006, translation: "ALIAS" }
			// { value: 20000, translation: "USER" }
		];

		vm.getCatEventID = function (value) {
			if (arguments.length) { // Set
				$scope.modEvent.eventID = value;
			} else { // Get
				return vm.eventIDGetterObject[$scope.modEvent.eventID];
			}
		};
		vm.eventIDGetterObject = {
			// Structure Example
			// 20000: { 20000 },
			// 20001: { 20000 }
		};

		vm.eventTypeOptions = {
			// Structure Example
			// 20000: [{ value: 20000, translation: "EVENT_TYPES_USERADDED" },
			//			{ value: 20001, translation: "EVENT_TYPES_USERDELETED" }]
		};

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			fillOptions();
			$scope.$watch('modEvent.name', function (newValue, oldValue) { if (newValue != oldValue && oldValue !== undefined) { changeEventName(); } });
		}

		function fillOptions() {
			var eventIDTracker = {};
			for (var key in availableEvents) {
				var event = availableEvents[key];
				var nKey = Number(key);

				// vm.eventCategoryOptions
				if (eventIDTracker[event.categoryResourceId] === undefined) {
					vm.eventCategoryOptions.push({ value: nKey, translation: event.categoryResourceId.replace("@", "").toUpperCase() });
					eventIDTracker[event.categoryResourceId] = nKey;
				}

				// vm.eventIDGetterObject
				vm.eventIDGetterObject[nKey] = eventIDTracker[event.categoryResourceId];

				// vm.eventTypeOptions
				if (vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] === undefined)
					vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] = [];
				vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]].push({ value: nKey, translation: "EVENT_TYPES_" + event.id.toUpperCase() });
			}
			vm.eventCategoryOptions = $filter("orderBy")(vm.eventCategoryOptions, "translation")
		}

		function changeEventName() {
			if (!$scope.modEvent.name) {
				$scope.isNameValid = false;
			} else {
				$scope.isNameValid = true;
				$scope.isValid = true;
			}
		}

		//// Use this to create events with default names for debugging purposes ////
		//$scope.$watch('modEvent.eventID', function (newValue, oldValue) { if (newValue != oldValue && oldValue != undefined) { debugEventName(newValue); } });
		//function debugEventName(newValue) {
		//	var friendlyEventID = vm.eventIDGetterObject[newValue];
		//	var first;
		//	var second;
		//
		//	angular.forEach(vm.eventCategoryOptions, function (cat) {
		//		if (cat.value === friendlyEventID)
		//			first = $filter("translate")(cat.translation);
		//	});
		//
		//	angular.forEach(vm.eventTypeOptions[friendlyEventID], function (type) {
		//		if (type.value === newValue)
		//			second = $filter("translate")(type.translation);
		//	});
		//
		//	$scope.modEvent.name = first + ": " + second
		//};

		function save() {
			if (!modEvent.name) {
				$scope.isValid = false;
				$scope.isNameValid = false;
				return;
			}
			$mdDialog.hide({event: $scope.modEvent});
		}

		function cancel() {
			$mdDialog.cancel();
		}
	}

})();
