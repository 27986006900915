(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsContentFilterController", domainSettingsContentFilterController);

	function domainSettingsContentFilterController($rootScope, $http, $q, $filter, $mdDialog, $state, $translate,
		contentFilterData, errorHandling, coreDataSettings) {

		var vm = this;
		vm.filterId = parseInt($state.params.contentfilterid, 10) || "new";
		vm.filter = null;
		vm.filterTypes = contentFilterData.filterTypes;
		vm.matchTypes = contentFilterData.matchTypes;
		vm.actionTypes = contentFilterData.actionTypes;
		vm.folders = null;
		vm.contentFilters = [];

		// Functions
		vm.save = save;
		vm.deleteThis = deleteThis;
		vm.addCondition = addCondition;
		vm.editCondition = editCondition;
		vm.addAction = addAction;
		vm.editAction = editAction;
		vm.getActionArgument = getActionArgument;
		vm.getActionTypeDescription = getActionTypeDescription;
		vm.getFilterArgument = getFilterArgument;
		vm.getFilterCondition = getFilterCondition;

		activate();

		////////////////////////////////////

		function activate() {

			$http
				.get("~/api/v1/settings/domain/content-filter-groups")
				.then(onDataLoaded, errorHandling.report);

			function onDataLoaded(success) {
				vm.folders = [];

				vm.filter = null;
				vm.contentFilters = success.data.contentFilterGroups || [];
				var maxOrder = 0;
				angular.forEach(vm.contentFilters, function (value) {
					if (value.id === vm.filterId) vm.filter = value;
					if (maxOrder < value.order)
						maxOrder = value.order;
				});
				if (!vm.filter) {
					vm.filter = { actions: [], filters: [], internalType: 0, matchType: 0, title: null };
				}
				vm.filter.filters = vm.filter.filters || [];
				vm.filter.actions = vm.filter.actions || [];

				vm.enableWildcard = false;
				angular.forEach(vm.filter.filters, function (value) { if (value.isWildcard) vm.enableWildcard = true; });
				if (vm.filterId === "new") vm.filter.order = maxOrder + 1;
				window.setTimeout(function () {
					document.getElementById("FilterNameInput").focus();
				}, 100);
			}
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			// Commit wildcard state
			angular.forEach(vm.filter.filters, function (value) { value.isWildcard = vm.enableWildcard; });
			angular.forEach(vm.filter.actions, function (action) {
				if (action.actionType === 4) {
					for (var index = 0; index < vm.folders.length; index++) {
						if (vm.folders[index].translatedPath == action.argument) {
							action.argument = vm.folders[index].name;
						}
					}
				}
			});

			var params;
			if (vm.filterId === "new") {
				var temp = $.grep(vm.contentFilters, function (filter) { return filter.title.toUpperCase() === vm.filter.title.toUpperCase(); });
				if (temp.length > 0) {
					errorHandling.report($translate.instant("CONTENT_FILTERING_NAME_IN_USE", { title: vm.filter.title }));
					return;
				}
				params = JSON.stringify({ toAdd: [vm.filter] });
			} else {
				params = JSON.stringify({ contentFilterGroups: [vm.filter] });
			}
			$http
				.post("~/api/v1/settings/domain/content-filter-groups", params)
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess(success) {
				if (!success.data.success) {
					errorHandling.report(success);
					return;
				}
				form.$setPristine();
				$rootScope.$broadcast("user-settings:changed");
				$state.go("^");
			}
		}

		function deleteThis(event) {
			if (vm.filterId === "new") {
				$state.go("^");
				return;
			}

			var confirm = $mdDialog
				.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
				.targetEvent(event);

			$mdDialog
				.show(confirm)
				.then(onDoDelete, function () { });

			function onDoDelete() {
				var params = JSON.stringify({ toRemove: [vm.filter.id] });
				$http
					.post("~/api/v1/settings/domain/content-filter-groups", params)
					.then(function () {
						vm.skipNavigateConfirm = true;
						$state.go("^");
					}, errorHandling.report);
			}
		}

		function addCondition(ev, form) {
			$mdDialog
				.show({
					locals: {
						newRule: true,
						filterId: -1,
						ruleTypes: angular.copy(contentFilterData.filterTypes),
						ruleCategories: angular.copy(contentFilterData.filterCategories),
						oldRule: { applyLogicalNot: false, fieldType: -1, filterType: 2, isWildcard: vm.enableWildcard, searchArguments: [] },
						usedRules: vm.filter.filters,
						wildcard: vm.enableWildcard,
						"internalType": "0"
					},
					controller: "userFilterRuleEditController",
					controllerAs: "FiMoCtrl",
					templateUrl: "app/settings/shared/content-filters/filter-rule.dlg.html",
					targetEvent: ev
				})
				.then(function (success) {
					vm.filter.filters.push(success.rule);
					form.$setDirty();
				}, function () { });
		}

		function editCondition(ev, rule, form) {
			$mdDialog
				.show({
					locals: {
						newRule: false,
						filterId: vm.filter.id,
						ruleTypes: $.extend(true, [], contentFilterData.filterTypes),
						ruleCategories: $.extend(true, [], contentFilterData.filterCategories),
						oldRule: $.extend(true, {}, rule),
						usedRules: vm.filter.filters,
						wildcard: vm.enableWildcard,
						"internalType": "0"
					},
					controller: "userFilterRuleEditController",
					controllerAs: "FiMoCtrl",
					templateUrl: "app/settings/shared/content-filters/filter-rule.dlg.html",
					targetEvent: ev
				})
				.then(function (success) {
					var index;
					if (success.deleteRule) {
						index = vm.filter.filters.indexOf(rule);
						if (index !== -1) {
							vm.filter.filters.splice(index, 1);
							form.$setDirty();
						}
						return;
					}

					index = vm.filter.filters.indexOf(rule);
					if (index === -1)
						vm.filter.filters.push(success.rule);
					else
						vm.filter.filters[index] = success.rule;

					form.$setDirty();
				}, function () { });
		}

		function addAction(ev, form) {
			$mdDialog
				.show({
					locals: {
						oldAction: { actionType: 1, argument: "", isDefaultAction: false },
						newAction: true,
						filterActions: $.extend(true, [], contentFilterData.actionTypes),
						usedActions: vm.filter.actions,
						folders: vm.folders,
						validateMail: coreDataSettings.validateEmailAddress,
						type: "domain"
					},
					controller: "userFilterActionEditController",
					controllerAs: "FiMoCtrl",
					templateUrl: "app/settings/shared/content-filters/filter-action.dlg.html",
					targetEvent: ev
				})
				.then(function (success) {
					vm.filter.actions.push(success.saveAction);
					form.$setDirty();
				}, function () { });
		}

		function editAction(ev, action, form) {
			$mdDialog
				.show({
					locals: {
						oldAction: $.extend(true, {}, action),
						newAction: false,
						filterActions: $.extend(true, [], contentFilterData.actionTypes),
						usedActions: vm.filter.actions,
						folders: vm.folders,
						validateMail: coreDataSettings.validateEmailAddress,
						type: "domain"
					},
					controller: "userFilterActionEditController",
					controllerAs: "FiMoCtrl",
					templateUrl: "app/settings/shared/content-filters/filter-action.dlg.html",
					targetEvent: ev
				})
				.then(function (success) {
					var index;
					if (success.deleteAction) {
						index = vm.filter.actions.indexOf(action);
						if (index !== -1) {
							vm.filter.actions.splice(index, 1);
							form.$setDirty();
						}
						return;
					}

					index = vm.filter.actions.indexOf(action);
					if (index === -1)
						vm.filter.actions.push(success.saveAction);
					else
						vm.filter.actions[index] = success.saveAction;

					form.$setDirty();
				}, function () { });
		}

		function getFilterCondition(filter) {
			switch (filter.fieldType) {
				case 0:
				case 1:
				case 10:
				case 11:
				case 17:
				case 18:
					return filter.applyLogicalNot
						? $filter("translate")("DOES_NOT_MATCH")
						: $filter("translate")("MATCHES");
				case 3:
				case 4:
				case 5:
				case 6:
				case 7:
				case 8:
				case 9:
					return filter.applyLogicalNot
						? $filter("translate")("CONTAINS_NOT")
						: $filter("translate")("CONTAINS");
				case 2:
				case 12:
				case 13:
				case 14:
				case 15:
				case 16:
				case 19:
				case 20:
				case 21:
				case 22:
				case 23:
				case 24:
				case 25:
				case 26:
				case 27:
				case 28:
				case 29:
				default:
					return "";
			}
		}

		function getFilterArgument(filter) {
			switch (filter.fieldType) {
				case 0:
				case 1:
				case 3:
				case 4:
				case 5:
				case 6:
				case 7:
				case 8:
				case 9:
				case 10:
				case 11:
				case 17:
				case 18:
				case 28:
					if (filter.searchArguments) {
						if (!filter.searchArguments || filter.searchArguments.length === 0) {
							return '""';
						}
						else if (filter.searchArguments.length === 1) {
							return '"' + (filter.searchArguments[0] ? filter.searchArguments[0] : "") + '"';
						} else {
							return $filter("translate")("MULTIPLE", { count: filter.searchArguments.length });
						}
					} else {
						return '""';
					}
				case 23:
				case 24:
					if (filter.searchArguments) {
						if (filter.searchArguments[0] === "1") {
							return $filter("translate")("NO");
						} else {
							return $filter("translate")("YES");
						}
					} else {
						return "";
					}
				case 19:
				case 25:
					return filter.searchArguments
						? filter.searchArguments[0] ? $filter("bytes")(filter.searchArguments[0], 1) : ""
						: $filter("translate")("X_BYTES", { count: 0 });
				case 26:
					return filter.searchArguments
						? filter.searchArguments[0] ? $filter("translate")("X_BYTES", { count: filter.searchArguments[0] }) : ""
						: "";
				case 27:
					if (filter.searchArguments) {
						var startTime = $filter("date")(filter.searchArguments[0], "shortDate");
						var endTime = $filter("date")(filter.searchArguments[1], "shortDate");
						return $filter("translate")("CONTENT_FILTERING_TIME_OF_DAY", { start: startTime, end: endTime });
					} else {
						return "";
					}
				case 29:
					if (filter.searchArguments && filter.searchArguments[0]) {
						switch (filter.searchArguments[0]) {
							case "low":
								return $filter("translate")("CONTENT_FILTERING_SPAM_LOW");
							case "normal":
								return $filter("translate")("CONTENT_FILTERING_SPAM_MED");
							case "high":
								return $filter("translate")("HIGH");
						}
					} else {
						return "";
					}
				case 2:
				case 12:
				case 13:
				case 14:
				case 15:
				case 16:
				default:
					return "";
			}
		}

		function getActionArgument(action) {
			switch (action.actionType) {
				case 6:
				case 5:
				case 7:
				case 2:
				case 4:
					var arg = action.argument ? action.argument : "";
					if (arg !== "") {
						angular.forEach(vm.folders, function (folder) {
							if (arg === folder.path) {
								arg = folder.name;
							}
						});
					}
					return arg;
				case 9:
					switch (action.argument) {
						case "low":
							return $filter("translate")("LOW");
						case "normal":
							return $filter("translate")("NORMAL");
						case "high":
							return $filter("translate")("HIGH");
					}
					break;
				case 8:
				case 10:
				case 1:
				case 3:
				case 0:
				default:
					break;
			}
			return "";
		}

		function getActionTypeDescription(action) {
			var actionType = $.grep(vm.actionTypes, function(at) { return at.id === action.actionType });
			return actionType.length > 0
				? actionType[0].translate
				: vm.actionTypes[action.actionType].translate;
		}
	}
})();
